.home {
  display: flex;
  flex-flow: column;
  flex: 3;
  height: 100%;
  overflow: scroll;
}

.home-widgets {
  display: grid;
  grid-column: 1;
  grid-template-rows: 270px 200px auto;
  height: calc(100% - 82px);
  width: 100%;
  grid-gap: 24px;
}

.home-card {
  padding: 8px 24px;
  background-color: #ffff;
  box-shadow: #63636333 0px 2px 8px 0px;
  border-radius: 6px;
}

.home-header {
  height: 82px;
}

.first-row {
  grid-row: 1;
}

.second-row {
  grid-row: 2;
  grid-column: 1 / 5;
  background: linear-gradient(to right, #01094e, #024783);
  color: #ffff;
}

.third-row {
  grid-row: 3;
  grid-column: 1 / 5;
}

.card-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 18px;
}

.card-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 3;
}

.card-value {
  font-size: 34px;
  font-weight: 600;
  width: 70%;
  text-align: left;
  font-family: "Montserrat", sans-serif !important;
  color: rgba(50, 90, 140, 1) !important;
}

.home a {
  color: rgba(50, 90, 140, 1) !important;
  text-decoration: none;
}

.card-icon {
  width: 30%;
  margin-left: auto;
  text-align: right;
}

.card-desc {
  margin-top: 12px;
  width: 100%;
  font-style: italic;
  font-weight: 600;
}

.desc-warning {
  font-weight: 600;
}

.current-night {
  display: flex;
  flex-flow: column;
}

.stats-jobs,
.stats-jobs-error {
  font-weight: 600;
}

.stats-jobs-error {
  color: #eb6060;
}

.stats-jobs-success {
  color: #7cd992;
}

.calendar {
  width: 100%;
}

.first-background-event {
  font-weight: 600;
  color: black;
}

.second-background-event {
  display: flex;
  align-items: center;
}
