.contacts {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.ant-tabs {
  height: 100%;
}

.BaseTable__row-cell-text {
  width: 100%;
}

.table-loading-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding-top: 75px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
}
