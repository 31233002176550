.map {
  height: calc(100% - 56px);
  width: 100%;
}

.map-header {
  display: flex;
  flex-flow: row nowrap;
  height: 56px;
  position: relative;
}

.map-filters {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-left: auto;
}

.map-breadcrumb {
  height: 56px;
  font-size: 16px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.map-minimap {
  width: 850px;
  margin-top: 8px;
  margin-left: 0%;
  margin-right: auto;
  position: absolute;
  right: 0;
  height: 100%;
}

.tooltip-item {
  padding-bottom: 12px;
}

.tooltip-header {
  padding: 12px 0px;
  font-weight: 600;
}

.breadcrumb-link {
  border-radius: 10px;
  text-align: center;
  margin: 0px 8px;
}
