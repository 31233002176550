.group-dashboard {
  display: flex;
  flex-flow: column;
  flex: 3;
  height: 100%;
}

.group-widgets {
  display: grid;
  grid-column: 1;
  grid-auto-rows: 200px 280px;
  grid-template-columns: 1fr 1fr 1fr;
  height: calc(100% - 82px);
  width: 100%;
  grid-column-gap: 24px;
  grid-row-gap: 36px;
}

.group-card {
  display: flex;
  flex-flow: column;
  padding: 8px 24px;
  background-color: #ffff;
  box-shadow: #63636333 0px 2px 8px 0px;
  border-radius: 6px;
}

.group-header {
  height: 82px;
}

.group-first-row {
  grid-row: 1;
}

.group-second-row {
  grid-row: 2;
  grid-column: 1 / 4;
  background: linear-gradient(to right, #01094e, #024783);
  color: #ffff;
}

.group-third-row {
  grid-row: 3;
  grid-column: 1 / 4;
}

.group-dashboard a {
  color: rgba(50, 90, 140, 1) !important;
  text-decoration: none;
}

.group-night {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  flex: 3;
}

.night-row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.night-col {
  margin-right: 64px;
  display: flex;
  flex-flow: column;
}

.night-text {
  font-size: 16px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.night-table {
  overflow: scroll;
  height: 100%;
  min-height: 0px;
}

.group-map {
  height: 100px;
  width: 100%;
  margin-top: auto;
}

.group-tools {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.group-envs {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  overflow: scroll;
  grid-gap: 12px;
}

.groups-envs-card {
  height: 100%;
}

.group-env {
  height: 100%;
  height: 110px;
  width: 300px;
  border-radius: 5px;
  padding: 8px;
}

.group-env-success {
  border: 3px solid rgb(124, 217, 146);
  background-color: rgb(240, 255, 241);
}

.group-env-warning {
  border: 3px solid rgb(247, 228, 99);
  background-color: rgb(254, 255, 240) !important;
}

.group-env-error {
  border: 3px solid rgb(235, 96, 96);
  background-color: rgb(255, 242, 240) !important;
}

.env-name {
  font-weight: 500;
}

.env-server {
  font-size: 12px;
}

.group-tabs,
.group-tabs .ant-tabs,
.group-tabs .ant-tabs-content-holder,
.group-tabs .ant-tabs-content {
  height: 100%;
}

.servers-dashboard {
  display: flex;
  flex-flow: column;
}

.server-card {
  background-color: white;
  height: 400px;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-flow: column;
}

.server-card-content {
  display: flex;
  flex-flow: row;
  height: 350px;
}

.server-card-left {
  display: flex;
  width: 30%;
  height: 100%;
  flex-flow: column;
}

.server-disk-pie {
  height: 70%;
}

.server-bench {
  flex: 3;
}

.server-graphs {
  flex: 3;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
}

.server-disk-history {
  width: 100%;
  height: 350px;
  padding: 24px;
}

.server-bench tr td:first-child {
  font-weight: 700;
  width: 100px;
}

.server-bench tr th {
  width: 100px;
  text-align: left;
}
