.app-popover {
  width: 250px;
  display: grid;
  grid-template-columns: 125px 125px;
  grid-gap: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.app-item {
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: column;
  cursor: pointer;
  height: 100%;
  padding: 6px 0px;
}

.item-label {
  font-size: 11px;
  font-weight: 600;
}

.item-icon img {
  width: 100%;
  height: 48px;
}
