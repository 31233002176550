html {
  overflow: hidden;
}

.logs-modal .ant-modal {
  height: 70vh !important;
}

.logs-modal .ant-modal-body {
  overflow: scroll;
  height: 70vh;
}

.ant-layout-content {
  height: calc(100vh - 52px);
  width: 100;
}

.modal-title {
  line-height: 32px;
  font-weight: 600;
  font-size: 18px;
}

.modal-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.modal-header button {
  margin-right: 24px;
}

.ant-modal-close-x {
  height: 64px;
  line-height: 64px;
}

.main-content {
  padding: 12px 32px;
  width: 100%;
  overflow: scroll;
  flex: 3;
  display: flex;
  flex-flow: column;
}

.stats-card {
  margin: 16px;
  border-radius: 8px;
}

.ant-form-item-label label {
  min-width: 190px;
  font-weight: 500;
}

.form-row {
  display: flex;
  flex-flow: row nowrap;
}

.form-column {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.form-item {
  margin: 0px 12px;
  margin-bottom: 12px;
  width: 100%;
}

.form-item .ant-card {
  height: 100%;
}

.clients-header {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 12px;
  align-items: center;
}

.ant-form-item-extra {
  color: #f39c12;
}

.tooltip,
.server-tooltip {
  background-color: rgb(0, 0, 0);
  font-size: 14px;
  padding: 2px 12px;
  border-radius: 4px;
  color: #dddd;
}

.gantt {
  height: calc(100vh - 96px);
  display: flex;
  flex-flow: column;
}

.gantt-options {
  padding: 24px 56px;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.gantt-chart {
  width: 100%;
  flex: 3;
}

.series-group {
  fill: rgb(240, 242, 245) !important;
}

.messages-content {
  display: flex;
  flex-flow: column;
}

.message-item {
  display: flex;
  flex-flow: row nowrap;
}

.message-label {
  font-weight: 600;
  min-width: 150px;
}

.layout {
  display: flex;
  flex-flow: row nowrap;
  height: calc(100vh - 52px);
  width: 100vw;
}

.side-menu {
  height: 100%;
  width: 300px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.side-menu .fold {
  position: absolute;
  left: 100%;
  margin-left: -16px;
  top: 28px;
  z-index: 10;
  background: #ffff;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.side-menu ul {
  height: 100%;
}

.ant-layout {
  height: 100vh;
}

.ant-layout-header {
  background: #ffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 2;
  padding: 0;
  height: 52px;
  display: flex;
  align-items: center;
}

.connect-link,
.connect-link a {
  margin-left: auto;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.connect-link:hover,
.connect-link a:hover,
.app-button:hover {
  color: #1890ff;
}

.app-button {
  font-size: 18px;
  margin-left: 32px;
}

.content {
  padding: 24px 48px;
  min-height: calc(100vh - 64px);
}

.ant-list-item-meta-avatar {
  display: flex;
  flex-flow: column;
  height: 48px;
  justify-content: center;
  align-items: center;
}

.vpn-header {
  display: flex;
  flex-flow: row nowrap;
}

.vpn-header button {
  margin-left: 12px;
}

.form-row {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 24px;
}

.form-row .form-label {
  min-width: 120px;
  line-height: 32px;
  font-weight: 400;
}

.rds-data {
  padding-top: 12px;
}

.exploit-view {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ant-input {
  flex: 3;
}

.scrollable {
  overflow: scroll;
  min-height: 0px;
  flex: 3;
}

.ant-tabs-content {
  height: 100%;
}

.ant-tabs-tabpane {
  display: flex;
  flex-flow: column;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #ffc53d;
  margin-left: 8px;
  border-radius: 50%;
  display: inline-block;
}

.ant-transfer-list {
  width: 50%;
  height: 500px;
}

.action-modal {
  display: flex;
  flex-flow: column;
}

.action-modal-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.action-modal-label {
  font-size: 14px;
  font-weight: 500;
}

.dates-presets {
  display: flex;
  margin: 12px 0px;
  width: 100%;
  justify-content: center;
}

.date-presets-value {
  margin-right: 8px;
}

.date-presets-title {
  width: 100%;
  text-align: center;
  height: 24px;
  font-weight: 600;
}
